import HomeIcon from "../utils/HomeIcon";
import TicketIcon from "../utils/TicketIcon";
import UsersIcon from "../utils/UsersIcon";
import UserGroupIcon from "../utils/UserGroupIcon";
import WalletIcon from "../utils/WalletIcon";
import BriefcaseIcon from "../utils/BriefcaseIcon";
import BanknotesIcon from "../utils/BanknotesIcon";
import ChatIcon from "../utils/ChatIcon";
import DocumentTextIcon from "../utils/DocumentTextIcon";
import DocumentCheckIcon from "../utils/DocumentCheckIcon";
import ArrowUpTrayIcon from "../utils/ArrowUpTrayIcon";

const userNavigation = [
  { name: "Dashboard", path: "/dashboard", icon: HomeIcon },
  { name: "Todos", path: "/todos", icon: DocumentCheckIcon },
  { name: "Events", path: "/events", icon: TicketIcon },
  { name: "Users", path: "/users", icon: UsersIcon },
  { name: "Committees", path: "/committees", icon: UserGroupIcon },
  { name: "Contributions", path: "/contributions", icon: WalletIcon },
  {
    name: "Service Providers",
    path: "/service-providers",
    icon: BriefcaseIcon,
  },
  { name: "Budgets", path: "/budgets", icon: BanknotesIcon },
  { name: "SMS", path: "/sms-contents", icon: ChatIcon },
  { name: "Reports", path: "/reports", icon: DocumentTextIcon },
];

const adminNavigation = [
  { name: "Dashboard", path: "/administrator/dashboard", icon: HomeIcon },
  { name: "Events", path: "/administrator/events", icon: TicketIcon },
  { name: "Users", path: "/administrator/users", icon: UsersIcon },
  {
    name: "SMS Bundles",
    path: "/administrator/sms-bundles",
    icon: ChatIcon,
  },
  { name: "Regions", path: "/administrator/regions", icon: DocumentTextIcon },
  {
    name: "Old Data",
    path: "/administrator/old-datas",
    icon: ArrowUpTrayIcon,
  },
];

export { userNavigation, adminNavigation };
