import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import useAuthAxios from "../../hooks/useAuthAxios";
import useAxios from "../../hooks/useAxios";
import Modal from "../../utils/Modal";
import { toast } from "react-toastify";

import AddIcon from "../../utils/AddIcon";
import Loader from "../../hocs/Loader";
import ArrowUpTrayIcon from "../../utils/ArrowUpTrayIcon";
import Select from "react-select";

const OldData = () => {
  const compname = "Old Data Upload";
  const app_url = "old-datas";
  const axios = useAuthAxios();
  const [response, error, loading, axiosFetch, responseData] = useAxios();
  const [open, setOpen] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const tableNames = [
    { value: "Contribution", label: "Contribution" },
    { value: "Budget", label: "Budget" },
    { value: "Todo", label: "Todo" },
    { value: "ServiceProvider", label: "Service Provider" },
    { value: "Event", label: "Event" },
  ];
  const resetForm = () => {
    reset({
      table: "",
      file: "",
    });
    setOpen(false);
    setSelectedFile("");
  };

  const onSubmit = (data) => {
    let formdata = new FormData();
    if (data.file) {
      formdata.append("table_file", data.file[0], data.file[0].name);
      formdata.append("table", data.table);
    }
    const url = app_url;
    const method = "POST";
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    postData(formdata, url, method, config);
  };

  const postData = async (data, url, method, config) => {
    setLoadingSpin(true);
    await axios[method.toLowerCase()](url, data, config).then(
      (result) => {
        setLoadingSpin(false);
        toast.success(result.data.message);
        resetForm();
      },
      (error) => {
        setLoadingSpin(false);
        if (error.response.status === 403) {
          toast.error(error.response.data.detail);
        } else {
          if (error.response.data.message) {
            toast.error(error.response.data.message);
          } else if (error.response.data.non_field_errors) {
            toast.error(error.response.data.non_field_errors[0]);
          }
        }
      }
    );
  };

  const handleFileIputChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setSelectedFile(file.name);
    }
  };

  return (
    <>
      {(loading || loadingSpin) && <Loader />}
      <section
        className="rounded bg-white 
        shadow-md w-full  h-fit  
        px-4 sm:px-6 lg:px-8 py-4"
      >
        <div className="mx-auto">
          <h1 className="text-3xl font-normal text-gray-900">
            {`${compname}s`}
          </h1>
        </div>
        <div className="flex justify-end">
          <button
            onClick={() => setOpen(true)}
            title="Add"
            className="m-1 px-2 py-2 border border-green-500 rounded text-sm font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none "
          >
            <AddIcon />
          </button>
        </div>
        <Modal modalHeader={`Import Old Data`} open={open}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="mt-4 relative p-6">
              <div className="grid grid-cols-1 pb-4 gap-6">
                <label className="relative">
                  <Controller
                    name="table"
                    control={control}
                    defaultValue={null}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 6,
                          border: 1,
                          colors: {
                            ...theme.colors,
                            text: "gray",
                            primary25: "lightgray",
                            primary: "#4b5563",
                          },
                        })}
                        classNamePrefix="mySelect"
                        options={tableNames}
                        value={
                          tableNames.find((c) => c.value === field.value) || ""
                        }
                        onChange={(val) => field.onChange(val.value)}
                        className={`text-gray-700 text-base font-light w-full
                            border-1 rounded-md border-opacity-50 outline-none
                            ${
                              errors.table
                                ? "border-red-600 focus:border-red-600"
                                : "border-gray-600"
                            } 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`}
                      />
                    )}
                  />
                  <span
                    className={`
                            placeholder-shown:hidden
                          focus:text-gray-500 bg-white transform -translate-y-7 -translate-x-4 scale-75 rounded-md
                            text-base text-gray-600
                            text-opacity-80  left-0 top-4
                            mx-2 px-2 absolute 
                            transition duration-200`}
                  >
                    Table
                  </span>
                  {errors.table && (
                    <p className="text-red-600 p-2 font-light">
                      This field is required
                    </p>
                  )}
                </label>
              </div>
              <div className="grid grid-cols-1 pb-4 gap-6">
                <label
                  className={`${
                    errors.file
                      ? "border-red-400 focus:border-red-400"
                      : "border-gray-500"
                  }flex flex-col rounded-lg border-4 border-dashed w-full h-30 p-5 group text-center`}
                >
                  <div className="h-full w-full text-center flex flex-col justify-center items-center  ">
                    <span className="text-gray-500">
                      <ArrowUpTrayIcon />
                    </span>
                    <p className="pointer-none text-gray-500 ">
                      Click to select a file
                    </p>
                  </div>
                  <input
                    type="file"
                    className="hidden"
                    {...register("file", {
                      required: true,
                      onChange: handleFileIputChange,
                    })}
                    accept=".xlsx,.xls"
                  />
                </label>
                {selectedFile && (
                  <div className="bg-gray-50 rounded-lg py-2 ">
                    <p className="text-gray-700 px-2 font-light">
                      <span className="font-medium">Selected file:</span>{" "}
                      {selectedFile}
                    </p>
                  </div>
                )}
                {errors.file && (
                  <p className="text-red-600 px-2 font-light">
                    Please choose a file
                  </p>
                )}
              </div>
            </div>
            <div className="flex py-4 px-6 justify-end">
              <button
                onClick={() => resetForm()}
                type="button"
                className="m-1 py-2 px-4 border-0 border-transparent text-md font-medium rounded-md border-gray-300 text-gray-700 hover:bg-gray-50 focus:outline-none sm:text-sm"
              >
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                className="m-1 py-2 px-4 border border-transparent text-md font-medium rounded-md text-black bg-yellow-400 hover:bg-yellow-500 focus:outline-none sm:text-sm"
              >
                <span>Upload</span>
              </button>
            </div>
          </form>
        </Modal>
      </section>
    </>
  );
};
export default OldData;
