import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../hocs/Loader';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const PasswordReset = () => {
  const { register, handleSubmit, formState:{errors}} = useForm();
  const navigate = useNavigate();
  const [ loading, setLoading ] = useState(false);

  const onSubmit = async(data) => {
    try {
      setLoading(true)
      await axios.post(
        `${API_ENDPOINT}/auth/users/reset_password/`,
        data,
        {
          headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json' 
          }
        }
      );
      setLoading(false)
      toast.success('Request Successfully Sent');
      navigate('/', {replace: true});
    } catch (error) {
      setLoading(false)
      if (error?.response) {
        const errorResponse = JSON.stringify(error.response.data);
        toast.error(errorResponse);
      }
    }
  };

  return (
    <>
      {loading && <Loader />}
      <section className="flex items-center justify-center md:pt-12 md:px-4 sm:px-0 lg:px-8">
        <div className="max-w-md w-full space-y-8 bg-white md:p-10 p-6 rounded-md">
          <h1 className="text-gray-700 text-4xl font-medium text-center">
            Password Reset
          </h1>

          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="mt-8 max-w-md">
              <div className="grid grid-cols-1 gap-6">
                <label className="relative">
                  <input
                    type="email"
                    className={`text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            ${
                              errors.email
                                ? "border-red-600 focus:border-red-600"
                                : "border-gray-600"
                            } 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`}
                    placeholder="Email address"
                    {...register("email", {
                      required: true,
                      pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                    })}
                  />
                  <span
                    className="
                  text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200"
                  >
                    Email
                  </span>
                  {errors.email && errors.email.type === "required" && (
                    <p className="text-red-600 p-2 font-light">
                      This field is required
                    </p>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <p className="text-red-600 p-2 font-light">
                      Email is not valid
                    </p>
                  )}
                </label>
                <button
                  className="group 
                  relative w-full 
                  flex justify-center 
                  py-2 px-4 
                  border border-transparent 
                  text-lg font-normal 
                  rounded-md text-black 
                  bg-yellow-400 hover:bg-yellow-500 
                  focus:outline-none"
                  type="submit"
                >
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default PasswordReset;