import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import useAuthAxios from "../../hooks/useAuthAxios";
import useAxios from "../../hooks/useAxios";
import Modal from "../../utils/Modal";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";
import Pagination from "../../utils/Pagination";
import ChevronLeft from "../../utils/ChevronLeft";
import ChevronRight from "../../utils/ChevronRight";
import DeleteIcon from "../../utils/DeleteIcon";
import EditIcon from "../../utils/EditIcon";
import IF from "../../utils/IF";
import AddIcon from "../../utils/AddIcon";
import ChevronDoubleLeft from "../../utils/ChevronDoubleLeft";
import ChevronDoubleRight from "../../utils/ChevronDoubleRight";
import SearchIcon from "../../utils/SearchIcon";
import Select from "react-select";
import DeleteModal from "../../utils/DeleteModal";
import CashIcon from "../../utils/CashIcon";
import ChatIcon from "../../utils/ChatIcon";
import Sms from "../../utils/Sms";
import { NumberWithCommas, NumberWithoutCommas } from "../../utils/Helpers";
import Loader from "../../hocs/Loader";
import ArrowUpTrayIcon from "../../utils/ArrowUpTrayIcon";
import CustomBadge from "../../utils/CustomBadge";
import ArrowDownTrayIcon from "../../utils/ArrowDownTrayIcon";
import ExcelTemplate from "../../assets/templates/contribution_template.xlsx";

const Contribution = () => {
  const compname = "Contribution";
  const app_url = "contributions";
  const axios = useAuthAxios();
  const [response, error, loading, axiosFetch, responseData] = useAxios();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openDeletePayment, setOpenDeletePayment] = useState(false);
  const [openSms, setOpenSms] = useState(false);
  const [openFileUpload, setOpenFileUpload] = useState(false);
  const [id, setId] = useState("");
  const [search, setSearch] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const {
    register: registerContri,
    handleSubmit: handleSubmitPayment,
    reset: resetContri,
    setValue: setValueContri,
    control: controlContri,
    formState: { errors: errorsContri },
  } = useForm();
  const {
    register: registerFileUp,
    handleSubmit: handleSubmitFileUp,
    reset: resetFileUp,
    formState: { errors: errorsFileUp },
  } = useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [contributor, setContributor] = useState({});
  const [payments, setPayments] = useState([]);
  const [recipientData, setRecipientData] = useState({});
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");

  const event_id = localStorage.getItem("id");
  const [pageSize, setPageSize] = useState({
    value: 10,
    label: 10,
  });

  const contributionTypes = [
    { value: "Pledge", label: "Pledge" },
    { value: "Card", label: "Card" },
  ];
  const pageSizeOptions = [
    { value: 10, label: 10 },
    { value: 15, label: 15 },
    { value: 20, label: 20 },
    { value: 30, label: 30 },
    { value: 100, label: 100 },
  ];

  const onChangePageSize = (e) => {
    setPageSize(e);
    axiosFetch({
      axiosInstance: axios,
      method: "GET",
      url: `${app_url}?page_size=${e.value}&search=${search}&event=${event_id}`,
    });
  };

  const getData = () => {
    axiosFetch({
      axiosInstance: axios,
      method: "GET",
      url: `${app_url}?page_size=${pageSize.value}&search=${search}&event=${event_id}`,
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const resetForm = () => {
    reset({
      fulname: "",
      collector: "",
      mobile: "",
      email: "",
      pledge: "",
      type: "",
    });
    resetContri({
      date: "",
      amount: "",
    });
    resetFileUp({
      file: "",
    });
    setIsEdit(false);
    setOpen(false);
    setOpenPayment(false);
    setOpenFileUpload(false);
  };

  const onSubmit = (data) => {
    data.event = event_id;
    let mobile = data.mobile;
    data.mobile = "255" + mobile.substr(1);
    data.pledge = NumberWithoutCommas(data.pledge);

    if (data.id) {
      const url = `${app_url}/${data.id}`;
      const method = "PUT";
      postData(data, url, method);
    } else {
      const url = `${app_url}`;
      const method = "POST";
      postData(data, url, method);
    }
  };

  const onSubmitPayment = (data) => {
    data.amount = NumberWithoutCommas(data.amount);
    data.contribution = id;
    data.date = format(data.date, "yyyy-MM-dd");
    const url = `contribution-payments`;
    const method = "POST";
    postPaymentData(data, url, method);
  };

  const onShowEditModal = (data) => {
    let mobile = data.mobile;
    data.mobile = "0" + mobile.substr(3);

    const fields = [
      "id",
      "fulname",
      "collector",
      "email",
      "mobile",
      "pledge",
      "type",
    ];
    fields.forEach((field) => setValue(field, data[field]));
    setIsEdit(true);
    setOpen(true);
  };

  const onShowDetailModal = (data) => {
    const contribution_id = data.id;
    const url = `contribution-payments?event=${event_id}&contribution=${contribution_id}`;
    const method = "GET";
    getDetail("", url, method);
  };

  const onShowAddPaymentModal = () => {
    setOpenDetail(false);
    setOpenPayment(true);
    setId(contributor.id);
  };

  const onShowDeleteModal = (id) => {
    setId(id);
    setOpenDelete(true);
  };

  const onShowDeletePaymentModal = (id) => {
    setId(id);
    setOpenDeletePayment(true);
  };

  const onDelete = () => {
    const url = `${app_url}/${id}`;
    const method = "DELETE";
    postData("", url, method);
  };

  const onDeletePayment = () => {
    // const newPayments = payments.filter((item) => item.id !== id);
    // setPayments(newPayments);
    const url = `contribution-payments/${id}`;
    const method = "DELETE";
    postPaymentData("", url, method);
  };

  const fetchPage = (pageNumber) => {
    const new_url = `${app_url}?page=${pageNumber}&page_size=${pageSize.value}&search=${search}&event=${event_id}`;

    axiosFetch({
      axiosInstance: axios,
      method: "GET",
      url: `${new_url}`,
    });
  };

  const postPaymentData = async (data, url, method) => {
    setLoadingSpin(true);
    await axios[method.toLowerCase()](url, data).then(
      (result) => {
        setLoadingSpin(false);
        toast.success(result.data.message);
        resetForm();
        setOpenDeletePayment(false);
        const contribution_id = contributor.id;
        const url = `contribution-payments?event=${event_id}&contribution=${contribution_id}`;
        const method = "GET";
        getDetail("", url, method);
        setOpenDetail(true);
      },
      (error) => {
        setLoadingSpin(false);
        const errorData = error.response?.data;
        if (error.response?.status === 403) {
          toast.error(error.response?.data.detail);
        } else if (error.response?.data.non_field_errors) {
          toast.error(error.response?.data.non_field_errors[0]);
        } else {
          toast.error(errorData.message);
        }
      }
    );
  };

  const postData = async (data, url, method) => {
    setLoadingSpin(true);
    await axios[method.toLowerCase()](url, data).then(
      (result) => {
        setLoadingSpin(false);
        toast.success(result.data.message);
        resetForm();
        setOpenDelete(false);
        getData();
      },
      (error) => {
        setLoadingSpin(false);
        const errorData = error.response?.data;
        if (error.response?.status === 403) {
          toast.error(error.response?.data.detail);
        } else if (error.response?.data.non_field_errors) {
          toast.error(error.response?.data.non_field_errors[0]);
        } else {
          toast.error(errorData.message);
        }
      }
    );
  };

  const getDetail = async (data, url, method) => {
    await axios[method.toLowerCase()](url, data).then(
      (result) => {
        if (result.status === 200) {
          setPayments(result.data.payments);
          setContributor(result.data.contribution);
          getData();
          setOpenDetail(true);
          result.data.status === 400
            ? toast.error(result.data.message)
            : toast.success(result.data.message);
        }
      },
      (error) => {
        const errorData = JSON.stringify(error.response.data);
        toast.error(errorData);
      }
    );
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearch(searchValue);
    axiosFetch({
      axiosInstance: axios,
      method: "GET",
      url: `${app_url}?page_size=${pageSize.value}&event=${event_id}&search=${searchValue}`,
    });
  };

  const sendSms = (id) => {
    setRecipientData({
      id: id,
      group: "Contributor",
      send_to_type: "Individual",
    });
    setOpenSms(true);
  };

  const closeSmsModal = () => {
    setOpenSms(false);
  };

  const loadingSwitch = (v) => {
    setLoadingSpin(v);
  };

  const onShowFileUpload = () => {
    setOpenFileUpload(true);
    setSelectedFile("");
  };

  const handleFileIputChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setSelectedFile(file.name);
    }
  };

  const onSubmitFileUp = (data) => {
    let formdata = new FormData();
    if (data.file) {
      formdata.append("contributors_file", data.file[0], data.file[0].name);
      formdata.append("event", event_id);
    }
    const url = `contribution-upload`;
    const method = "POST";
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    fileUpload(formdata, url, method, config);
  };

  const fileUpload = async (data, url, method, config) => {
    setLoadingSpin(true);
    await axios[method.toLowerCase()](url, data, config).then(
      (result) => {
        setLoadingSpin(false);
        toast.success(result.data.message);
        resetForm();
        getData();
      },
      (error) => {
        setLoadingSpin(false);
        const errorData = error.response.data;
        if (error.response.status === 403) {
          toast.error(error.response.data.detail);
        } else {
          toast.error(errorData.message);
        }
        resetForm();
      }
    );
  };

  const checkStatus = (status) => {
    if (status === "paid") {
      return "green";
    } else if (status === "partial") {
      return "indigo";
    } else {
      return "yellow";
    }
  };

  return (
    <>
      {(loading || loadingSpin) && <Loader />}
      <section
        className="rounded bg-white 
        shadow-md w-full h-fit
        px-4 sm:px-6 lg:px-8 py-4"
      >
        <div className="mx-auto">
          <h1 className="text-3xl font-normal text-gray-900">
            {`${compname}s`}
          </h1>
        </div>
        <div className="flex justify-end">
          <div className="inline-flex">
            <button
              onClick={() => onShowFileUpload()}
              title="Import"
              className="m-0 px-4 py-2 border border-blue-500 rounded-l-md
               text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 
               focus:outline-none "
            >
              <ArrowUpTrayIcon />
            </button>
            <button
              onClick={() => setOpen(true)}
              title="Add"
              className="m-0 px-4 py-2 border border-green-500 rounded-r-md
               text-sm font-medium text-white bg-green-500 hover:bg-green-600
                focus:outline-none "
            >
              <AddIcon />
            </button>
          </div>
        </div>
        <div>
          <div className="overflow-x-auto sm:-mx-4 lg:-mx-8 p-6">
            <div className="py-4 flex flex-wrap  gap-y-4">
              <div className="flex-auto w-64 md:max-w-sm lg:max-w-sm4">
                <label className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">
                  Search
                </label>
                <div className="relative">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <SearchIcon />
                  </div>
                  <input
                    type="search"
                    className="block p-4 pl-10 
                        w-full text-base text-gray-900
                        border-1 rounded-md border-opacity-50 border-gray-600
                        focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1"
                    placeholder="Search..."
                    onChange={(e) => handleSearch(e)}
                    required
                  />
                </div>
              </div>
              <div className="flex-auto w-32">
                <div className="flex justify-end">
                  <p className="px-4 text-sm text-gray-600">Rows per page:</p>
                  <Select
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 6,
                      border: 1,
                      colors: {
                        ...theme.colors,
                        text: "gray",
                        primary25: "lightgray",
                        primary: "#4b5563",
                      },
                    })}
                    defaultValue={pageSize}
                    onChange={(pageSize) => {
                      onChangePageSize(pageSize);
                    }}
                    options={pageSizeOptions}
                  />
                </div>
              </div>
            </div>
            {!loading && !error && !responseData.length > 0 && (
              <p>No Data to Display</p>
            )}
            <IF condition={responseData.length > 0}>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr className="text-gray-700 uppercase tracking-wider">
                    <th className="px-4 py-3 text-left text-xs">Name</th>
                    <th className="px-4 py-3 text-left text-xs">Collector</th>
                    <th className="px-4 py-3 text-left text-xs">Mobile</th>
                    <th className="px-4 py-3 text-left text-xs">Email</th>
                    <th className="px-4 py-3 text-left text-xs">Type</th>
                    <th className="px-4 py-3 text-left text-xs">Status</th>
                    <th className="px-4 py-3 text-right text-xs">Amount</th>
                    <th className="px-4 py-3 text-right text-xs">Paid</th>
                    <th className="px-4 py-3 text-right text-xs">Balance</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {responseData.map((data, i) => (
                    <tr
                      key={i}
                      className="whitespace-no-wrap
                    text-sm text-gray-600 py-4"
                    >
                      <td className="px-4 py-3">{data.fulname}</td>
                      <td className="px-4 py-3">{data.collector}</td>
                      <td className="px-4 py-3">{data.mobile}</td>
                      <td className="px-4 py-3">{data.email}</td>
                      <td className="px-4 py-3">{data.type}</td>
                      <td className="px-4 py-3">
                        <CustomBadge color={checkStatus(data.status)}>
                          {data.status}
                        </CustomBadge>
                      </td>
                      <td className="px-4 py-3 text-right">
                        {NumberWithCommas(data.pledge)}
                      </td>
                      <td className="px-4 py-3 text-right">
                        {NumberWithCommas(data.paid)}
                      </td>
                      <td className="px-4 py-3 text-right">
                        {NumberWithCommas(data.balance)}
                      </td>
                      <td className="flex">
                        <button
                          onClick={() => sendSms(data.id)}
                          title="Send SMS"
                          type="button"
                          className="m-1 px-2 py-2 border 
                        border-green-500 rounded 
                        text-sm font-medium 
                        text-white bg-green-500 
                        hover:bg-green-600 focus:outline-none "
                        >
                          <ChatIcon />
                        </button>
                        <button
                          onClick={() => onShowDetailModal(data)}
                          title="Payments"
                          type="button"
                          className="m-1 px-2 py-2 border 
                        border-gray-500 rounded 
                        text-sm font-medium 
                        text-white bg-gray-500 
                        hover:bg-gray-600 focus:outline-none "
                        >
                          <CashIcon />
                        </button>
                        <button
                          onClick={() => onShowEditModal(data)}
                          title="Edit"
                          type="button"
                          className="m-1 px-2 py-2 border 
                        border-blue-500 rounded 
                        text-sm font-medium 
                        text-white bg-blue-500 
                        hover:bg-blue-600 focus:outline-none "
                        >
                          <EditIcon />
                        </button>
                        <button
                          onClick={() => onShowDeleteModal(data.id)}
                          title="Delete"
                          type="button"
                          className="m-1 px-2 py-2 border 
                          border-red-600 rounded text-sm 
                          font-medium text-white 
                          bg-red-600 hover:bg-red-700 
                          focus:outline-none "
                        >
                          <DeleteIcon />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination data={response}>
                <div
                  onClick={() => fetchPage(response.first)}
                  className={`relative inline-flex 
                  items-center px-2 py-2 rounded-l-md 
                  border border-gray-300 bg-white text-sm 
                  font-medium
                  ${
                    response.current !== response.first
                      ? "text-gray-500"
                      : "text-gray-200"
                  } 
                  ${
                    response.current !== response.first
                      ? "hover:bg-gray-100"
                      : "hover:bg-white"
                  } `}
                >
                  <span className="sr-only">First</span>
                  <ChevronDoubleLeft />
                </div>
                <div
                  onClick={() => fetchPage(response.current - 1)}
                  className={`relative inline-flex 
                  items-center px-2 py-2 
                  border border-gray-300 bg-white text-sm 
                  font-medium
                  ${response.previous ? "text-gray-500" : "text-gray-200"} 
                  ${
                    response.previous ? "hover:bg-gray-100" : "hover:bg-white"
                  } `}
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeft />
                </div>
                <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                  Page {response.current} of {response.last}
                </span>
                <div
                  onClick={() => fetchPage(response.current + 1)}
                  className={`relative inline-flex 
                  items-center px-2 py-2 
                  border border-gray-300 bg-white text-sm 
                  font-medium 
                  ${response.next ? "text-gray-500" : "text-gray-200"}
                  ${response.next ? "hover:bg-gray-100" : "hover:bg-white"}`}
                >
                  <span className="sr-only">Next</span>
                  <ChevronRight />
                </div>
                <div
                  onClick={() => fetchPage(response.last)}
                  className={`relative inline-flex 
                  items-center px-2 py-2 rounded-r-md 
                  border border-gray-300 bg-white text-sm 
                  font-medium 
                  ${
                    response.current !== response.last
                      ? "text-gray-500"
                      : "text-gray-200"
                  }
                  ${
                    response.current !== response.last
                      ? "hover:bg-gray-100"
                      : "hover:bg-white"
                  }`}
                >
                  <span className="sr-only">Last</span>
                  <ChevronDoubleRight />
                </div>
              </Pagination>
            </IF>
          </div>
        </div>
        <Modal
          modalHeader={isEdit ? `Edit ${compname}` : `New ${compname}`}
          open={open}
        >
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="mt-4 relative p-6">
              <div className="grid md:grid-cols-2 grid-cols-1 pb-4 gap-6">
                <label className="relative">
                  <input
                    type="text"
                    className={`text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            ${
                              errors.fulname
                                ? "border-red-600 focus:border-red-600"
                                : "border-gray-600"
                            } 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`}
                    placeholder="Fulname"
                    {...register("fulname", { required: true })}
                  />
                  <span
                    className="
                  text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200"
                  >
                    Fulname
                  </span>
                  {errors.fulname && (
                    <p className="text-red-600 p-2 font-light">
                      This field is required
                    </p>
                  )}
                </label>
                <label className="relative">
                  <input
                    type="text"
                    className={`text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            ${
                              errors.collector
                                ? "border-red-600 focus:border-red-600"
                                : "border-gray-600"
                            } 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`}
                    placeholder="Collector name"
                    {...register("collector", { required: true })}
                  />
                  <span
                    className="
                  text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200"
                  >
                    Collector
                  </span>
                  {errors.collector && (
                    <p className="text-red-600 p-2 font-light">
                      This field is required
                    </p>
                  )}
                </label>
                <label className="relative">
                  <input
                    type="email"
                    className={`text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            ${
                              errors.email
                                ? "border-red-600 focus:border-red-600"
                                : "border-gray-600"
                            } 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`}
                    placeholder="Email address(optional)"
                    {...register("email", {
                      required: false,
                      pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                    })}
                  />
                  <span
                    className="
                  text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200"
                  >
                    Email address
                  </span>
                  {errors.email && errors.email.type === "required" && (
                    <p className="text-red-600 p-2 font-light">
                      This field is required
                    </p>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <p className="text-red-600 p-2 font-light">
                      Email is not valid
                    </p>
                  )}
                </label>
                <label className="relative">
                  <input
                    type="text"
                    className={`text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            ${
                              errors.mobile
                                ? "border-red-600 focus:border-red-600"
                                : "border-gray-600"
                            } 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`}
                    placeholder="Mobile number"
                    {...register("mobile", {
                      required: true,
                      pattern: /^(\d{10})$/,
                    })}
                  />
                  <span
                    className="
                  text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200"
                  >
                    Mobile number
                  </span>
                  {errors.mobile && errors.mobile.type === "required" && (
                    <p className="text-red-600 p-2 font-light">
                      This field is required
                    </p>
                  )}
                  {errors.mobile && errors.mobile.type === "pattern" && (
                    <p className="text-red-600 p-2 font-light">
                      Mobile Number should contain 10 characters
                    </p>
                  )}
                </label>
                <label className="relative">
                  <input
                    type="text"
                    className={`text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            ${
                              errors.pledge
                                ? "border-red-600 focus:border-red-600"
                                : "border-gray-600"
                            } 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`}
                    placeholder="Pledge amount"
                    {...register("pledge", {
                      required: true,
                      onChange: (v) =>
                        setValue("pledge", NumberWithCommas(v.target.value)),
                    })}
                  />
                  <span
                    className="
                  text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200"
                  >
                    Pledge
                  </span>
                  {errors.pledge && (
                    <p className="text-red-600 p-2 font-light">
                      This field is required
                    </p>
                  )}
                </label>
                <label className="relative">
                  <Controller
                    name="type"
                    control={control}
                    defaultValue={null}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 6,
                          border: 1,
                          colors: {
                            ...theme.colors,
                            text: "gray",
                            primary25: "lightgray",
                            primary: "#4b5563",
                          },
                        })}
                        classNamePrefix="mySelect"
                        options={contributionTypes}
                        value={
                          contributionTypes.find(
                            (c) => c.value === field.value
                          ) || ""
                        }
                        onChange={(val) => field.onChange(val.value)}
                        className={`text-gray-700 text-base font-light w-full
                            border-1 rounded-md border-opacity-50 outline-none
                            ${
                              errors.type
                                ? "border-red-600 focus:border-red-600"
                                : "border-gray-600"
                            } 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`}
                      />
                    )}
                  />
                  <span
                    className={`
                            placeholder-shown:hidden
                          focus:text-gray-500 bg-white transform -translate-y-7 -translate-x-4 scale-75 rounded-md
                            text-base text-gray-600
                            text-opacity-80  left-0 top-4
                            mx-2 px-2 absolute 
                            transition duration-200`}
                  >
                    Type
                  </span>
                  {errors.type && (
                    <p className="text-red-600 p-2 font-light">
                      This field is required
                    </p>
                  )}
                </label>
              </div>
            </div>
            <div className="flex py-4 px-6 justify-end">
              <button
                onClick={() => resetForm()}
                type="button"
                className="m-1 py-2 px-4 border-0 border-transparent text-md font-medium rounded-md border-gray-300 text-gray-700 hover:bg-gray-50 focus:outline-none sm:text-sm"
              >
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                className="m-1 py-2 px-4 border border-transparent text-md font-medium rounded-md text-black bg-yellow-400 hover:bg-yellow-500 focus:outline-none sm:text-sm"
              >
                <span>{isEdit ? "Update" : "Create"}</span>
              </button>
            </div>
          </form>
        </Modal>
        <Modal
          modalHeader={isEdit ? `Edit Payment` : `New Payment`}
          open={openPayment}
        >
          <form
            onSubmit={handleSubmitPayment(onSubmitPayment)}
            autoComplete="off"
          >
            <div className="p-6 grid md:grid-cols-2 grid-cols-1 pb-4 gap-6 text-gray-700">
              <p>
                <em>Contributor</em> : {contributor.fulname}
              </p>
              <p>
                <em>Status</em> : {contributor.status}
              </p>
              <p>
                <em>Amount</em> : {NumberWithCommas(contributor.pledge)}
              </p>
              <p>
                <em>Paid</em> : {NumberWithCommas(contributor.paid)}
              </p>
            </div>
            <div className="mt-4 relative p-6">
              <div className="grid  grid-cols-1 pb-4 gap-6">
                <label className="relative" onClick={(e) => e.preventDefault()}>
                  <Controller
                    name="date"
                    control={controlContri}
                    defaultValue={null}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        className={`text-gray-700 text-base font-light h-12 w-full px-4
                              border-1 rounded-md border-opacity-50 outline-none
                              ${
                                errors.date
                                  ? "border-red-600 focus:border-red-600"
                                  : "border-gray-600"
                              } 
                              focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                              transition duration-200`}
                        selected={field.value}
                        onChange={(date) => field.onChange(date)}
                        shouldCloseOnSelect={true}
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />
                  <span
                    className={`
                            placeholder-shown:hidden
                          focus:text-gray-500 bg-white transform -translate-y-7 -translate-x-4 scale-75 rounded-md
                            text-base text-gray-900
                            text-opacity-80  left-0 top-4
                            mx-2 px-2 absolute 
                            transition duration-200`}
                  >
                    Date
                  </span>
                  {errorsContri.date && (
                    <p className="text-red-600 p-2 font-light">
                      This field is required
                    </p>
                  )}
                </label>
                <label className="relative">
                  <input
                    type="text"
                    className={`text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            ${
                              errors.amount
                                ? "border-red-600 focus:border-red-600"
                                : "border-gray-600"
                            } 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`}
                    placeholder="Amount"
                    {...registerContri("amount", {
                      required: true,
                      onChange: (v) =>
                        setValueContri(
                          "amount",
                          NumberWithCommas(v.target.value)
                        ),
                    })}
                  />
                  <span
                    className="
                  text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200"
                  >
                    Amount
                  </span>
                  {errorsContri.amount && (
                    <p className="text-red-600 p-2 font-light">
                      This field is required
                    </p>
                  )}
                </label>
              </div>
            </div>
            <div className="flex py-4 px-6 justify-end">
              <button
                onClick={() => resetForm()}
                type="button"
                className="m-1 py-2 px-4 border-0 border-transparent text-md font-medium rounded-md border-gray-300 text-gray-700 hover:bg-gray-50 focus:outline-none sm:text-sm"
              >
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                className="m-1 py-2 px-4 border border-transparent text-md font-medium rounded-md text-black bg-yellow-400 hover:bg-yellow-500 focus:outline-none sm:text-sm"
              >
                <span>{isEdit ? "Update" : "Create"}</span>
              </button>
            </div>
          </form>
        </Modal>
        <Modal modalHeader={`${compname} Detail`} open={openDetail}>
          <div className="relative p-6">
            <div className="grid md:grid-cols-2 grid-cols-1 pb-4 gap-6 text-gray-700">
              <p>
                <em>Contributor</em> : {contributor.fulname}
              </p>
              <p>
                <em>Status</em> : {contributor.status}
              </p>
              <p>
                <em>Amount</em> : {NumberWithCommas(contributor.pledge)}
              </p>
              <p>
                <em>Paid</em> : {NumberWithCommas(contributor.paid)}
              </p>
            </div>
            <div className="flex justify-end">
              {contributor.status !== "paid" && (
                <button
                  onClick={() => onShowAddPaymentModal()}
                  title="Add"
                  className="m-1 px-2 py-2 border border-green-500 rounded text-sm font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none "
                >
                  <AddIcon />
                </button>
              )}
            </div>

            <p className="text-gray-800 font-medium text-lg pb-2">Payments</p>
            {!payments.length > 0 && (
              <p className="text-gray-600 font-normal text-base">
                No Data to Display
              </p>
            )}
            {payments && payments.length > 0 && (
              <>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr className="text-gray-700 uppercase tracking-wider">
                      <th className="px-4 py-3 text-left text-xs">Date</th>
                      <th className="px-4 py-3 text-left text-xs">Amount</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {payments.map((data, i) => (
                      <tr
                        key={i}
                        className="whitespace-no-wrap
                    text-sm text-gray-600 py-4"
                      >
                        <td className="px-4 py-3">
                          {format(new Date(data.date), "dd/MM/yyyy")}
                        </td>
                        <td className="px-4 py-3">
                          {NumberWithCommas(data.amount)}
                        </td>
                        <td className="flex">
                          <button
                            onClick={() => onShowDeletePaymentModal(data.id)}
                            title="Delete"
                            type="button"
                            className="m-1 px-2 py-2 border 
                          border-red-600 rounded text-sm 
                          font-medium text-white 
                          bg-red-600 hover:bg-red-700 
                          focus:outline-none "
                          >
                            <DeleteIcon />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
          <div className="flex py-4 px-6 justify-end">
            <button
              onClick={() => setOpenDetail(false)}
              type="button"
              className="m-1 py-2 px-4 border-0 border-transparent text-md font-medium rounded-md border-gray-300 text-gray-700 hover:bg-gray-50 focus:outline-none sm:text-sm"
            >
              <span>Cancel</span>
            </button>
          </div>
        </Modal>
        <Modal modalHeader={`Import Contribution`} open={openFileUpload}>
          <div className="p-6">
            <p className="font-medium text-xl">
              Steps for uploading Contribution
            </p>
            <ul className="ml-4 list-decimal text-base font-normal text-gray-800">
              <li className="py-1">
                Download excel template here{" "}
                <a
                  href={ExcelTemplate}
                  download="contribution_template"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    type="button"
                    className="inline-flex m-1 py-1 px-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none sm:text-sm"
                  >
                    <ArrowDownTrayIcon />
                    <span className="pl-2">Download</span>
                  </button>
                </a>
              </li>
              <li className="py-1">
                Fill all your Contribution without deleting heading
              </li>
              <li className="py-1">Upload file</li>
            </ul>
          </div>
          <form
            onSubmit={handleSubmitFileUp(onSubmitFileUp)}
            autoComplete="off"
          >
            <div className=" relative px-6">
              <div className="grid grid-cols-1 pb-4 gap-6">
                <label
                  className={`${
                    errorsFileUp.file
                      ? "border-red-400 focus:border-red-400"
                      : "border-gray-500"
                  }flex flex-col rounded-lg border-4 border-dashed w-full h-30 p-5 group text-center`}
                >
                  <div className="h-full w-full text-center flex flex-col justify-center items-center  ">
                    <span className="text-gray-500">
                      <ArrowUpTrayIcon />
                    </span>
                    <p className="pointer-none text-gray-500 ">
                      Click to select a file
                    </p>
                  </div>
                  <input
                    type="file"
                    className="hidden"
                    {...registerFileUp("file", {
                      required: true,
                      onChange: handleFileIputChange,
                    })}
                    accept=".xlsx,.xls"
                  />
                </label>
                {selectedFile && (
                  <div className="bg-gray-50 rounded-lg py-2 ">
                    <p className="text-gray-700 px-2 font-light">
                      <span className="font-medium">Selected file:</span>{" "}
                      {selectedFile}
                    </p>
                  </div>
                )}
                {errorsFileUp.file && (
                  <p className="text-red-600 px-2 font-light">
                    Please choose a file
                  </p>
                )}
              </div>
            </div>
            <div className="flex py-4 px-6 justify-end">
              <button
                onClick={() => resetForm()}
                type="button"
                className="m-1 py-2 px-4 border-0 border-transparent text-md font-medium rounded-md border-gray-300 text-gray-700 hover:bg-gray-50 focus:outline-none sm:text-sm"
              >
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                className="m-1 py-2 px-4 border border-transparent text-md font-medium rounded-md text-black bg-yellow-400 hover:bg-yellow-500 focus:outline-none sm:text-sm"
              >
                <span>Upload</span>
              </button>
            </div>
          </form>
        </Modal>
        <DeleteModal
          open={openDelete}
          itemName={compname}
          onDelete={() => onDelete()}
          close={() => setOpenDelete(false)}
        />
        <DeleteModal
          open={openDeletePayment}
          itemName={`Payment`}
          onDelete={() => onDeletePayment()}
          close={() => setOpenDeletePayment(false)}
        />
        {/* send sms */}
        <Modal modalHeader={`Send Text Message`} open={openSms}>
          <Sms
            recipientData={recipientData}
            closeModal={closeSmsModal}
            loader={loadingSwitch}
          />
        </Modal>
      </section>
    </>
  );
};
export default Contribution;
