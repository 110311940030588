import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./components/home/Home";
import Activate from "./components/auth/Activate";
import Login from "./components/auth/Login";
import PasswordReset from "./components/auth/PasswordReset";
import PasswordResetConfirm from "./components/auth/PasswordResetConfirm";
import Signup from "./components/auth/Signup";
import EventList from "./components/event/EventList";
import "./styles/tailwind.css";
import Layout from "./hocs/Layout";
import EventLayout from "./hocs/EventLayout";
import AuthenticatedLayout from "./hocs/AuthenticatedLayout";
import ClientDashboard from "./components/dashboard/ClientDashboard";
import PersistLogin from "./components/auth/PersistLogin";
import Contribution from "./components/contribution/Contribution";
import EventSelect from "./components/event/EventSelect";
import ServiceProvider from "./components/service-provider/ServiceProvider";
import Budget from "./components/budget/Budget";
import Committee from "./components/committee/Committee";
import SmsContent from "./components/sms-content/SmsContent";
import SmsBundle from "./components/sms-bundle/SmsBundle";
import User from "./components/user/User";
import Report from "./components/report/Report";
import AuthenticatedAdminLayout from "./hocs/AuthenticatedAdminLayout";
import AdminDashboard from "./components/dashboard/AdminDashboard";
import AdminLogin from "./components/auth/AdminLogin";
import AllEvent from "./components/event/AllEvent";
import AdminUser from "./components/user/AdminUser";
import NotFound from "./components/auth/NotFound";
import Region from "./components/region/Region";
import Todo from "./components/todo/Todo";
import OldData from "./components/old-data/OldData";

const App = () => (
  <Routes>
    <Route path="/" element={<Layout />}>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/administrator/login" element={<AdminLogin />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/activate/:uid/:token" element={<Activate />} />
      <Route path="/password-reset" element={<PasswordReset />} />
      <Route
        path="/password/reset/confirm/:uid/:token"
        element={<PasswordResetConfirm />}
      />
    </Route>
    <Route element={<PersistLogin />}>
      <Route path="/" element={<AuthenticatedLayout />}>
        <Route path="/dashboard" element={<ClientDashboard />} />
        <Route path="/todos" element={<Todo />} />
        <Route path="/events" element={<EventList />} />
        <Route path="/contributions" element={<Contribution />} />
        <Route path="/service-providers" element={<ServiceProvider />} />
        <Route path="/budgets" element={<Budget />} />
        <Route path="/committees" element={<Committee />} />
        <Route path="/sms-contents" element={<SmsContent />} />
        <Route path="/users" element={<User />} />
        <Route path="/reports" element={<Report />} />
      </Route>
      <Route path="/" element={<AuthenticatedAdminLayout />}>
        <Route path="/administrator/dashboard" element={<AdminDashboard />} />
        <Route path="/administrator/events" element={<AllEvent />} />
        <Route path="/administrator/sms-bundles" element={<SmsBundle />} />
        <Route path="/administrator/sms-contents" element={<SmsContent />} />
        <Route path="/administrator/users" element={<AdminUser />} />
        <Route path="/administrator/regions" element={<Region />} />
        <Route path="/administrator/old-datas" element={<OldData />} />
      </Route>
      <Route element={<EventLayout />}>
        <Route path="/event-selection" element={<EventSelect />} />
      </Route>
    </Route>
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default App;
