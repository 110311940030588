import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useAuthAxios from "../../hooks/useAuthAxios";
import useAxios from "../../hooks/useAxios";
import Modal from "../../utils/Modal";
import { toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";
import Pagination from "../../utils/Pagination";
import ChevronLeft from "../../utils/ChevronLeft";
import ChevronRight from "../../utils/ChevronRight";
import DeleteIcon from "../../utils/DeleteIcon";
import EditIcon from "../../utils/EditIcon";
import IF from "../../utils/IF";
import AddIcon from "../../utils/AddIcon";
import ChevronDoubleLeft from "../../utils/ChevronDoubleLeft";
import ChevronDoubleRight from "../../utils/ChevronDoubleRight";
import SearchIcon from "../../utils/SearchIcon";
import Select from "react-select";
import DeleteModal from "../../utils/DeleteModal";
import ChatIcon from "../../utils/ChatIcon";
import Sms from "../../utils/Sms";
import Loader from "../../hocs/Loader";
import CustomBadge from "../../utils/CustomBadge";

const Committee = () => {
  const compname = "Committee";
  const app_url = "committees";
  const axios = useAuthAxios();
  const [response, error, loading, axiosFetch, responseData] = useAxios();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openSms, setOpenSms] = useState(false);
  const [id, setId] = useState("");
  const [search, setSearch] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [recipientData, setRecipientData] = useState({});
  const [loadingSpin, setLoadingSpin] = useState(false);

  const event_id = localStorage.getItem("id");
  const [pageSize, setPageSize] = useState({
    value: 10,
    label: 10,
  });

  const pageSizeOptions = [
    { value: 10, label: 10 },
    { value: 15, label: 15 },
    { value: 20, label: 20 },
    { value: 30, label: 30 },
    { value: 100, label: 100 },
  ];

  const onChangePageSize = (e) => {
    setPageSize(e);
    axiosFetch({
      axiosInstance: axios,
      method: "GET",
      url: `${app_url}?page_size=${e.value}&search=${search}&event=${event_id}`,
    });
  };

  const getData = () => {
    axiosFetch({
      axiosInstance: axios,
      method: "GET",
      url: `${app_url}?page_size=${pageSize.value}&search=${search}&event=${event_id}`,
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const resetForm = () => {
    reset({
      fulname: "",
      mobile: "",
      email: "",
      task: "",
    });
    setIsEdit(false);
    setOpen(false);
  };

  const onSubmit = (data) => {
    data.event = event_id;
    let mobile = data.mobile;
    data.mobile = "255" + mobile.substr(1);

    if (data.id) {
      const url = `${app_url}/${data.id}`;
      const method = "PUT";
      postData(data, url, method);
    } else {
      const url = `${app_url}`;
      const method = "POST";
      postData(data, url, method);
    }
  };

  const onShowEditModal = (data) => {
    let mobile = data.mobile;
    data.mobile = "0" + mobile.substr(3);
    const fields = ["id", "fulname", "email", "mobile", "task"];
    fields.forEach((field) => setValue(field, data[field]));
    setIsEdit(true);
    setOpen(true);
  };

  const onShowDeleteModal = (id) => {
    setId(id);
    setOpenDelete(true);
  };

  const onDelete = () => {
    const url = `${app_url}/${id}`;
    const method = "DELETE";
    postData("", url, method);
  };

  const fetchPage = (pageNumber) => {
    const new_url = `${app_url}?page=${pageNumber}&page_size=${pageSize.value}&search=${search}&event=${event_id}`;

    axiosFetch({
      axiosInstance: axios,
      method: "GET",
      url: `${new_url}`,
    });
  };

  const postData = async (data, url, method) => {
    setLoadingSpin(true);
    await axios[method.toLowerCase()](url, data).then(
      (result) => {
        setLoadingSpin(false);
        toast.success(result.data.message);
        resetForm();
        getData();
        setOpenDelete(false);
      },
      (error) => {
        setLoadingSpin(false);
        const errorData = JSON.stringify(error.response.data);
        if (error.response.status === 403) {
          toast.error(error.response.data.detail);
        } else {
          if (error.response.data.message) {
            toast.error(error.response.data.message);
          } else if (error.response.data.fulname) {
            toast.error(error.response.data.fulname[0]);
          } else if (error.response.data.non_field_errors) {
            toast.error(error.response.data.non_field_errors[0]);
          } else {
            toast.error(errorData);
          }
        }
      }
    );
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearch(searchValue);
    axiosFetch({
      axiosInstance: axios,
      method: "GET",
      url: `${app_url}?page_size=${pageSize.value}&event=${event_id}&search=${searchValue}`,
    });
  };

  const sendSms = (id) => {
    setRecipientData({
      id: id,
      group: "Committee",
      send_to_type: "Individual",
    });
    setOpenSms(true);
  };

  const closeSmsModal = () => {
    setOpenSms(false);
  };

  const loadingSwitch = (v) => {
    setLoadingSpin(v);
  };

  return (
    <>
      {(loading || loadingSpin) && <Loader />}
      <section
        className="rounded bg-white 
        shadow-md w-full  h-fit  
        px-4 sm:px-6 lg:px-8 py-4"
      >
        <div className="mx-auto">
          <h1 className="text-3xl font-normal text-gray-900">
            {`${compname}s`}
          </h1>
        </div>
        <div className="flex justify-end">
          <button
            onClick={() => setOpen(true)}
            title="Add"
            className="m-1 px-2 py-2 border border-green-500 rounded text-sm font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none "
          >
            <AddIcon />
          </button>
        </div>
        <div>
          <div className="overflow-x-auto sm:-mx-4 lg:-mx-8 p-6">
            <div className="py-4 flex flex-wrap  gap-y-4">
              <div className="flex-auto w-64 md:max-w-sm lg:max-w-sm4">
                <label className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">
                  Search
                </label>
                <div className="relative">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <SearchIcon />
                  </div>
                  <input
                    type="search"
                    className="block p-4 pl-10 
                        w-full text-base text-gray-900
                        border-1 rounded-md border-opacity-50 border-gray-600
                        focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1"
                    placeholder="Search..."
                    onChange={(e) => handleSearch(e)}
                    required
                  />
                </div>
              </div>
              <div className="flex-auto w-32">
                <div className="flex justify-end">
                  <p className="px-4 text-sm text-gray-600">Rows per page:</p>
                  <Select
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 6,
                      border: 1,
                      colors: {
                        ...theme.colors,
                        text: "gray",
                        primary25: "lightgray",
                        primary: "#4b5563",
                      },
                    })}
                    defaultValue={pageSize}
                    onChange={(pageSize) => {
                      onChangePageSize(pageSize);
                    }}
                    options={pageSizeOptions}
                  />
                </div>
              </div>
            </div>
            {!loading && !error && !responseData.length > 0 && (
              <p>No Data to Display</p>
            )}
            <IF condition={responseData.length > 0}>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr className="text-gray-700 uppercase tracking-wider">
                    <th className="px-4 py-3 text-left text-xs">Name</th>
                    <th className="px-4 py-3 text-left text-xs">Mobile</th>
                    <th className="px-4 py-3 text-left text-xs">Task</th>
                    <th className="px-4 py-3 text-left text-xs">Email</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {responseData.map((data, i) => (
                    <tr
                      key={i}
                      className="whitespace-no-wrap
                    text-sm text-gray-600 py-4"
                    >
                      <td className="px-4 py-3">{data.fulname}</td>
                      <td className="px-4 py-3">{data.mobile}</td>
                      <td className="px-4 py-3">
                        {data.task && (
                          <CustomBadge color="yellow">{data.task}</CustomBadge>
                        )}
                      </td>
                      <td className="px-4 py-3">{data.email}</td>
                      <td className="flex">
                        <button
                          onClick={() => sendSms(data.id)}
                          title="Send SMS"
                          type="button"
                          className="m-1 px-2 py-2 border 
                        border-green-500 rounded 
                        text-sm font-medium 
                        text-white bg-green-500 
                        hover:bg-green-600 focus:outline-none "
                        >
                          <ChatIcon />
                        </button>
                        <button
                          onClick={() => onShowEditModal(data)}
                          title="Edit"
                          type="button"
                          className="m-1 px-2 py-2 border 
                        border-blue-500 rounded 
                        text-sm font-medium 
                        text-white bg-blue-500 
                        hover:bg-blue-600 focus:outline-none "
                        >
                          <EditIcon />
                        </button>
                        <button
                          onClick={() => onShowDeleteModal(data.id)}
                          title="Delete"
                          type="button"
                          className="m-1 px-2 py-2 border 
                          border-red-600 rounded text-sm 
                          font-medium text-white 
                          bg-red-600 hover:bg-red-700 
                          focus:outline-none "
                        >
                          <DeleteIcon />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination data={response}>
                <div
                  onClick={() => fetchPage(response.first)}
                  className={`relative inline-flex 
                  items-center px-2 py-2 rounded-l-md 
                  border border-gray-300 bg-white text-sm 
                  font-medium
                  ${
                    response.current !== response.first
                      ? "text-gray-500"
                      : "text-gray-200"
                  } 
                  ${
                    response.current !== response.first
                      ? "hover:bg-gray-100"
                      : "hover:bg-white"
                  } `}
                >
                  <span className="sr-only">First</span>
                  <ChevronDoubleLeft />
                </div>
                <div
                  onClick={() => fetchPage(response.current - 1)}
                  className={`relative inline-flex 
                  items-center px-2 py-2 
                  border border-gray-300 bg-white text-sm 
                  font-medium
                  ${response.previous ? "text-gray-500" : "text-gray-200"} 
                  ${
                    response.previous ? "hover:bg-gray-100" : "hover:bg-white"
                  } `}
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeft />
                </div>
                <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                  Page {response.current} of {response.last}
                </span>
                <div
                  onClick={() => fetchPage(response.current + 1)}
                  className={`relative inline-flex 
                  items-center px-2 py-2 
                  border border-gray-300 bg-white text-sm 
                  font-medium 
                  ${response.next ? "text-gray-500" : "text-gray-200"}
                  ${response.next ? "hover:bg-gray-100" : "hover:bg-white"}`}
                >
                  <span className="sr-only">Next</span>
                  <ChevronRight />
                </div>
                <div
                  onClick={() => fetchPage(response.last)}
                  className={`relative inline-flex 
                  items-center px-2 py-2 rounded-r-md 
                  border border-gray-300 bg-white text-sm 
                  font-medium 
                  ${
                    response.current !== response.last
                      ? "text-gray-500"
                      : "text-gray-200"
                  }
                  ${
                    response.current !== response.last
                      ? "hover:bg-gray-100"
                      : "hover:bg-white"
                  }`}
                >
                  <span className="sr-only">Last</span>
                  <ChevronDoubleRight />
                </div>
              </Pagination>
            </IF>
          </div>
        </div>
        <Modal
          modalHeader={isEdit ? `Edit ${compname}` : `New ${compname}`}
          open={open}
        >
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="mt-4 relative p-6">
              <div className="grid md:grid-cols-2 grid-cols-1 pb-4 gap-6">
                <label className="relative">
                  <input
                    type="text"
                    className={`text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            ${
                              errors.fulname
                                ? "border-red-600 focus:border-red-600"
                                : "border-gray-600"
                            } 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`}
                    placeholder="Fulname"
                    {...register("fulname", {
                      required: true,
                      pattern: /^[a-zA-Z\s]+$/,
                    })}
                  />
                  <span
                    className="
                  text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200"
                  >
                    Fulname
                  </span>
                  {errors.fulname && errors.fulname.type === "required" && (
                    <p className="text-red-600 p-2 font-light">
                      This field is required
                    </p>
                  )}
                  {errors.fulname && errors.fulname.type === "pattern" && (
                    <p className="text-red-600 p-2 font-light">
                      Only alphabet characters are allowed
                    </p>
                  )}
                </label>
                <label className="relative">
                  <input
                    type="text"
                    className={`text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            ${
                              errors.mobile
                                ? "border-red-600 focus:border-red-600"
                                : "border-gray-600"
                            } 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`}
                    placeholder="Mobile number"
                    {...register("mobile", {
                      required: true,
                      pattern: /^(\d{10})$/,
                    })}
                  />
                  <span
                    className="
                  text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200"
                  >
                    Mobile number
                  </span>
                  {errors.mobile && errors.mobile.type === "required" && (
                    <p className="text-red-600 p-2 font-light">
                      This field is required
                    </p>
                  )}
                  {errors.mobile && errors.mobile.type === "pattern" && (
                    <p className="text-red-600 p-2 font-light">
                      Mobile Number should contain 10 characters
                    </p>
                  )}
                </label>
                <label className="relative">
                  <input
                    type="text"
                    className={`text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            ${
                              errors.task
                                ? "border-red-600 focus:border-red-600"
                                : "border-gray-600"
                            } 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`}
                    placeholder="Task(optional)"
                    {...register("task", { required: false })}
                  />
                  <span
                    className="
                  text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200"
                  >
                    Task
                  </span>
                  {errors.task && (
                    <p className="text-red-600 p-2 font-light">
                      This field is required
                    </p>
                  )}
                </label>
                <label className="relative">
                  <input
                    type="email"
                    className={`text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            ${
                              errors.email
                                ? "border-red-600 focus:border-red-600"
                                : "border-gray-600"
                            } 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`}
                    placeholder="Email address(optional)"
                    {...register("email", {
                      required: false,
                      pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                    })}
                  />
                  <span
                    className="
                  text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200"
                  >
                    Email address
                  </span>
                  {errors.email && errors.email.type === "required" && (
                    <p className="text-red-600 p-2 font-light">
                      This field is required
                    </p>
                  )}
                  {errors.email && errors.email.type === "" && (
                    <p className="text-red-600 p-2 font-light">
                      Email is not valid
                    </p>
                  )}
                </label>
              </div>
            </div>
            <div className="flex py-4 px-6 justify-end">
              <button
                onClick={() => resetForm()}
                type="button"
                className="m-1 py-2 px-4 border-0 border-transparent text-md font-medium rounded-md border-gray-300 text-gray-700 hover:bg-gray-50 focus:outline-none sm:text-sm"
              >
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                className="m-1 py-2 px-4 border border-transparent text-md font-medium rounded-md text-black bg-yellow-400 hover:bg-yellow-500 focus:outline-none sm:text-sm"
              >
                <span>{isEdit ? "Update" : "Create"}</span>
              </button>
            </div>
          </form>
        </Modal>
        <DeleteModal
          open={openDelete}
          itemName={compname}
          onDelete={() => onDelete()}
          close={() => setOpenDelete(false)}
        />
        <Modal modalHeader={`Send Text Message`} open={openSms}>
          <Sms
            recipientData={recipientData}
            closeModal={closeSmsModal}
            loader={loadingSwitch}
          />
        </Modal>
      </section>
    </>
  );
};
export default Committee;
