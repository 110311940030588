import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../hocs/Loader";
import ExclamationTriangleIcon from "../../utils/ExclamationTriangleIcon";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const Signup = () => {
  const passFormat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}$/;
  const charOnly = /^[a-zA-Z]+$/;
  const charOnlyWithSpace = /^[a-zA-Z\s]+$/;
  const formSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("This field is required")
      .min(3, "Name must have Min of 3 characters")
      .matches(charOnlyWithSpace, "Only alphabet characters are allowed"),
    last_name: Yup.string()
      .required("This field is required")
      .min(3, "Name must have Min of 3 characters")
      .matches(charOnly, "Only alphabet characters are allowed"),
    mobile: Yup.string()
      .required("This field is required")
      .max(10, "Mobile number must have Max of 10 characters"),
    email: Yup.string()
      .required("This field is required")
      .email("Invalid email format"),
    password: Yup.string()
      .required("This field is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        passFormat,
        "Password should contain at least one uppercase letter, lowercase letter, digit, and special symbol."
      ),
    re_password: Yup.string()
      .required("This field is required")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    let mobile = data.mobile;
    data.mobile = "255" + mobile.substr(1);
    data.name = `${data.first_name} ${data.last_name}`;
    delete data.first_name;
    delete data.last_name;

    try {
      setLoading(true);
      await axios.post(`${API_ENDPOINT}/auth/users/`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setLoading(false);
      toast.success("Account Successfully Created");
      navigate("/", { replace: true });
    } catch (error) {
      setLoading(false);
      if (error?.response) {
        const errorResponse = JSON.stringify(error.response.data);
        if (error.response.data.name) {
          toast.error(error.response.data.name[0]);
        } else {
          toast.error(errorResponse);
        }
      }
    }
  };

  return (
    <>
      {loading && <Loader />}
      <section className="flex items-center justify-center md:pt-12 md:px-4 sm:px-0">
        <div className="max-w-xl w-full space-y-8 bg-white md:p-10 p-6 rounded-md">
          <h1 className="text-gray-700 text-4xl font-medium text-center">
            Signup
          </h1>
          <p className="inline-flex w-full gap-3 bg-yellow-100 text-base font-light p-3 rounded-md">
            <ExclamationTriangleIcon size={`w-8 h-8`} />
            <span className="align-baseline">
              Activation link will be sent to the email provided
            </span>
          </p>

          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="mt-8 max-w-xl">
              <div className="grid md:grid-cols-2 grid-cols-1 pb-6 gap-6">
                <label className="relative">
                  <input
                    type="text"
                    className={`text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200
                            ${
                              errors.first_name
                                ? "border-red-600 focus:border-red-600"
                                : "border-gray-600"
                            }`}
                    placeholder="First name"
                    {...register("first_name")}
                  />
                  <span
                    className="text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200"
                  >
                    First name
                  </span>
                  {errors.first_name && (
                    <p className="text-red-600 p-2 font-light">
                      {errors.first_name?.message}
                    </p>
                  )}
                </label>
                <label className="relative">
                  <input
                    type="text"
                    className={`text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200
                            ${
                              errors.last_name
                                ? "border-red-600 focus:border-red-600"
                                : "border-gray-600"
                            }`}
                    placeholder="Last name"
                    {...register("last_name")}
                  />
                  <span
                    className="text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200"
                  >
                    Last name
                  </span>
                  {errors.last_name && (
                    <p className="text-red-600 p-2 font-light">
                      {errors.last_name?.message}
                    </p>
                  )}
                </label>
                <label className="relative">
                  <input
                    type="email"
                    className={`text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200
                            ${
                              errors.email
                                ? "border-red-600 focus:border-red-600"
                                : "border-gray-600"
                            }`}
                    placeholder="Email Address"
                    {...register("email")}
                  />
                  <span
                    className="text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200"
                  >
                    Email Address
                  </span>
                  {errors.email && (
                    <p className="text-red-600 p-2 font-light">
                      {errors.email?.message}
                    </p>
                  )}
                </label>
                <label className="relative">
                  <input
                    type="text"
                    className={`text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200
                            ${
                              errors.mobile
                                ? "border-red-600 focus:border-red-600"
                                : "border-gray-600"
                            }`}
                    placeholder="Mobile number"
                    {...register("mobile")}
                  />
                  <span
                    className="text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200"
                  >
                    Mobile number
                  </span>
                  {errors.mobile && (
                    <p className="text-red-600 p-2 font-light">
                      {errors.mobile?.message}
                    </p>
                  )}
                </label>
                <label className="relative">
                  <input
                    type="password"
                    className={`text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200
                            ${
                              errors.password
                                ? "border-red-600 focus:border-red-600"
                                : "border-gray-600"
                            }`}
                    placeholder="Password"
                    {...register("password")}
                  />
                  <span
                    className="text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200"
                  >
                    Password
                  </span>
                  {errors.password && (
                    <p className="text-red-600 p-2 font-light">
                      {errors.password?.message}
                    </p>
                  )}
                </label>
                <label className="relative">
                  <input
                    type="password"
                    className={`text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200
                            ${
                              errors.re_password
                                ? "border-red-600 focus:border-red-600"
                                : "border-gray-600"
                            }`}
                    placeholder="Confirm Password"
                    {...register("re_password")}
                  />
                  <span
                    className="text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200"
                  >
                    Confirm Password
                  </span>
                  {errors.re_password && (
                    <p className="text-red-600 p-2 font-light">
                      {errors.re_password?.message}
                    </p>
                  )}
                </label>
              </div>
              <div className="grid grid-cols-1 gap-4">
                <button
                  className="group 
                  relative w-full 
                  flex justify-center 
                  py-2 px-4 
                  border border-transparent 
                  text-lg font-normal 
                  rounded-md text-black 
                  bg-yellow-400 hover:bg-yellow-500 
                  focus:outline-none"
                  type="submit"
                >
                  Signup
                </button>
              </div>
            </div>
          </form>

          <p className="py-2 font-normal text-base text-center text-gray-600">
            Already have an account ?
            <Link
              to="/login"
              className="px-2 
              text-blue-500 
              hover:text-blue-700"
            >
              Login
            </Link>
          </p>
        </div>
      </section>
    </>
  );
};

export default Signup;
